var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.currentUser &&
      _vm.currentUser.subscriptionDetails &&
      _vm.currentUser.employeeDetails
  )?_c('div',{staticClass:"view view--toolbar"},[_c('Toolbar',[_c('h2',{},[_vm._v("Mijn gegevens aanpassen")])]),_c('Content',[(_vm.currentStateIs(this.states.ERROR) && !_vm.hasErrors)?_c('AlertError',[_vm._v(" Oops, er is een onbekende fout opgetreden bij het wijzigen van de gegevens. ")]):(_vm.currentStateIs(this.states.ERROR))?_c('AlertError',[_vm._v(" Oops, je bent een of meerdere velden vergeten in te vullen, of ze zijn niet goed ingevuld. Pas de velden hieronder alsjeblieft aan en probeer het nog eens. ")]):_vm._e(),(_vm.currentStateIs(this.states.COMPLETE) && _vm.currentUser.subscriptionDetails.enableAutomaticUpdateEmployeeDetails)?_c('AlertSuccess',[_vm._v(" Bedankt voor het doorgeven van je wijziging, de aanpassing is verwerkt. "),_c('br'),_c('br'),_c('router-link',{staticClass:"link",attrs:{"to":{ path: '/' }}},[_c('span',[_vm._v("Klik hier")])]),_vm._v(" om terug te keren naar het overzicht. ")],1):(_vm.currentStateIs(this.states.COMPLETE))?_c('AlertSuccess',[_vm._v(" Bedankt voor het doorgeven van je wijziging. We gaan ermee aan de slag! De aanpassingen worden binnen één werkdag verwerkt. "),_c('br'),_c('br'),_c('router-link',{staticClass:"link",attrs:{"to":{ path: '/' }}},[_c('span',[_vm._v("Klik hier")])]),_vm._v(" om terug te keren naar het overzicht. ")],1):_vm._e(),(!_vm.currentStateIs(this.states.COMPLETE))?_c('p',{staticClass:"paragraph",staticStyle:{"margin":"0 0 20px 5px"}},[_vm._v(" Neem contact op met de "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'help' }}},[_c('span',[_vm._v("klantenservice")])]),_vm._v(" als je gegevens van geblokkeerde velden wilt wijzigen. ")],1):_vm._e(),(
        _vm.currentStateIs(this.states.INITIAL) ||
          _vm.currentStateIs(this.states.LOADING) ||
          _vm.currentStateIs(this.states.ERROR)
      )?_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.updateEmployeeDetails()}}},[_vm._l((_vm.inputFields),function(field){return _c('FormInput',{key:("field-" + (field.name)),ref:field.name,refInFor:true,attrs:{"inputField":field},on:{"change":_vm.inputChange}})}),_c('FormButton',{attrs:{"caption":'Gegevens bijwerken',"loading":_vm.currentStateIs(this.states.LOADING),"disabled":!_vm.hasDirtyInput}})],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }