








































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import {
  SubscriptionApi,
  UpdateEmployeeDetailsCommand,
  Gender,
  AddressCompletionApi
} from '@/api'

import { User } from '@/types'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import _, { isNumber } from 'lodash'

interface InputFields {
  firstName: InputField;
  middleName: InputField;
  lastName: InputField;
  initials: InputField;
  postalCode: InputField;
  houseNumber: InputField;
  address: InputField;
  city: InputField;
  birthDate: InputField;
  gender: InputField;
  emailAddress: InputField;
  phonePersonal: InputField;
  phoneBusiness: InputField;
  iban: InputField;
  ibanAccountHolder: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})

export default class UserDetailEdit extends mixins(State) {
  inputFields: InputFields = {
    firstName: {
      name: 'firstName',
      value: this.$store.state.currentUser.employeeDetails.firstName,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Voornaam',
      errors: []
    },

    middleName: {
      name: 'middleName',
      value: this.$store.state.currentUser.employeeDetails.middleName,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Tussenvoegsel',
      errors: []
    },

    lastName: {
      name: 'lastName',
      value: this.$store.state.currentUser.employeeDetails.lastName,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Achternaam',
      errors: []
    },

    initials: {
      name: 'initials',
      value: this.$store.state.currentUser.employeeDetails.initials,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Initialen',
      errors: []
    },

    postalCode: {
      name: 'postalCode',
      value: this.$store.state.currentUser.employeeDetails.postalCode,
      required: true,
      type: 'text',
      placeholder: 'Postcode',
      errors: []
    },

    houseNumber: {
      name: 'houseNumber',
      value: '',
      required: false,
      type: 'number',
      placeholder: 'Huisnummer',
      errors: []
    },

    address: {
      name: 'address',
      value: this.$store.state.currentUser.employeeDetails.address,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Straatnaam',
      errors: []
    },

    city: {
      name: 'city',
      value: this.$store.state.currentUser.employeeDetails.city,
      required: false,
      type: 'text',
      disabled: true,
      placeholder: 'Woonplaats',
      errors: []
    },

    birthDate: {
      name: 'birthDate',
      value: this.$store.state.currentUser.employeeDetails.birthDate,
      required: false,
      type: 'date',
      disabled: true,
      placeholder: 'Geboortedatum',
      errors: []
    },

    gender: {
      name: 'gender',
      value: this.$store.state.currentUser.employeeDetails.gender,
      required: false,
      type: 'radio',
      disabled: true,
      options: [
        {
          label: 'Man',
          value: Gender.Male
        },
        {
          label: 'Vrouw',
          value: Gender.Female
        },
        {
          label: 'X',
          value: Gender.X
        },
        {
          label: 'Zeg ik liever niet',
          value: Gender.Unknown
        }
      ],
      placeholder: 'Geslacht',
      errors: []
    },

    emailAddress: {
      name: 'emailAddress',
      value: this.$store.state.currentUser.employeeDetails.emailAddress,
      required: false,
      type: 'email',
      disabled: true,
      placeholder: 'E-mailadres',
      errors: []
    },

    phonePersonal: {
      name: 'phonePersonal',
      value: this.$store.state.currentUser.employeeDetails.phonePersonal,
      required: true,
      type: 'tel',
      placeholder: 'Telefoon (persoonlijk)',
      errors: []
    },

    phoneBusiness: {
      name: 'phoneBusiness',
      value: this.$store.state.currentUser.employeeDetails.phoneBusiness,
      required: false,
      type: 'tel',
      placeholder: 'Telefoon (zakelijk)',
      errors: []
    },

    iban: {
      name: 'iban',
      value: this.$store.state.currentUser.employeeDetails.iban,
      required: this.$store.state.currentUser.employeeDetails.iban,
      type: 'text',
      placeholder: 'IBAN-nummer',
      errors: [],
      hidden: !this.$store.state.currentUser.employeeDetails.iban
    },

    ibanAccountHolder: {
      name: 'ibanAccountHolder',
      value: this.$store.state.currentUser.employeeDetails.ibanAccountHolder,
      required: this.$store.state.currentUser.employeeDetails.iban,
      type: 'text',
      placeholder: 'Naam rekeninghouder',
      errors: [],
      hidden: !this.$store.state.currentUser.employeeDetails.iban
    }
  }

  hasDirtyInput = false
  private addressApi: AddressCompletionApi

  private previouseHouseNumber: string
  private previousePostalcode: string

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  public constructor () {
    super()
    this.addressApi = new AddressCompletionApi()

    this.previouseHouseNumber = ''
    this.previousePostalcode = ''
  }

  public convertStringToGender (gender: string): Gender {
    switch (gender) {
      case 'Female': return Gender.Female
      case 'Male': return Gender.Male
      case 'X': return Gender.X
      default: return Gender.Unknown
    }
  }

  public async updateEmployeeDetails () {
    this.clearErrors()
    if (!this.validateInput()) {
      return
    }

    const api = new SubscriptionApi()
    const fields: UpdateEmployeeDetailsCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0,
      address: this.inputFields.address.value,
      postalCode: this.inputFields.postalCode.value,
      city: this.inputFields.city.value,
      phonePersonal: this.inputFields.phonePersonal.value,
      phoneBusiness: this.inputFields.phoneBusiness.value,
      iban: this.inputFields.iban.value,
      ibanAccountHolder: this.inputFields.ibanAccountHolder.value
    }
    try {
      this.state = this.states.LOADING
      this.clearErrors()
      await api.subscriptionUpdateDetails(fields)

      this.state = this.states.COMPLETE
    } catch (error) {
      this.state = this.states.ERROR

      this.setErrors(error.response.data.errors)

      this.$root.$emit('scrollToTop')
    }
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  private addressCheckDebounce = _.debounce(async (houseNumber: number, postalCode: string) => {
    const { data } = await this.addressApi.addressCompletionGetCompleteAddressForTheNetherlands({
      houseNumber: houseNumber,
      postalCode: postalCode.replace(' ', '')
    })

    if (data) {
      this.inputFields.address.value = `${data.street} ${data.houseNumber}`
      this.inputFields.city.value = data.city || ''
    }
  }, 750)

  public inputChange () {
    this.hasDirtyInput = true
    if ((this.inputFields.houseNumber.value || this.inputFields.postalCode.value) &&
          isNumber(Number(this.inputFields.houseNumber.value)) &&
          (this.previouseHouseNumber !== this.inputFields.houseNumber.value ||
          this.previousePostalcode !== this.inputFields.postalCode.value)) {
      this.previouseHouseNumber = this.inputFields.houseNumber.value
      this.previousePostalcode = this.inputFields.postalCode.value

      this.addressCheckDebounce(Number(this.inputFields.houseNumber.value), this.inputFields.postalCode.value)
    }
  }

  private validateInput () {
    let valid = true

    Object.keys(this.inputFields).forEach((key: string) => {
      let input
      if (Array.isArray(this.$refs[key])) {
        input = (this.$refs[key] as Element[])[0]
      } else {
        input = this.$refs[key]
      }
      valid = (input as FormInput).validate() && valid
    })

    return valid
  }

  get hasErrors () {
    let hasError = false
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      hasError = hasError || fieldValue.errors.length > 0
    })
    return hasError
  }
}
